<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_shipAchieveUpdateDelete" />

      <!-- 検索項目-->
      <v-container fluid>
        <v-row class="search-row">
          <!-- 取引先-->
          <div class="search-autocomplete first-search-item">
            <v-text-field
              dense
              readonly
              v-model="suppliersSelected"
              :label="$t('label.lbl_supplier')"
              class="txt-single"
              persistent-hint
              margin-bottom="0"
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 出荷先-->
          <div class="search-autocomplete">
            <v-text-field
              dense
              readonly
              v-model="shippingSelected"
              :label="$t('label.lbl_shippingTo')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 出荷No -->
          <div class="serch-textbox-arrivalNo">
            <v-text-field
              readonly
              dense
              v-model="txt_slipNo"
              maxlength="50"
              :label="$t('label.lbl_shippingNo')"
              class="txt-single"
            />
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 出荷実績日付-->
          <div class="serch-textbox-arrivalNo">
            <v-text-field
              readonly
              dense
              v-model="shippingAchieveDateDate"
              :label="$t('label.lbl_shipAchieveDate')"
              class="txt-single"
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 品質区分-->
          <div class="search-autocomplete">
            <v-text-field
              readonly
              dense
              v-model="shippingStatusSelected"
              :label="$t('label.lbl_arrivalStatus')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 責任区分-->
          <v-btn-toggle
            :disabled="true"
            mandatory
            v-model="isRes"
            color="blue darken-2"
            class="toggle black--text"
          >
            <v-btn
              mandatory
              :disabled="true"
              class="res-btn"
              v-for="item in blameDivList"
              :key="item.value"
              :value="item.value"
              small
            >
              {{ item.text }}</v-btn
            >
          </v-btn-toggle>
        </v-row>
        <!-- 2段目 -->
        <v-row>
          <div class="search-row-exeBtn first-search-item">
            <!-- トラック-->
            <div class="search-textbox" style="float: left">
              <v-text-field
                readonly
                dense
                v-model="truckNm"
                :label="$t('label.lbl_shippingCompanyName')"
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 有償/無償-->
            <div style="float: left">
              <v-btn-toggle
                :disabled="true"
                mandatory
                v-model="isPaid"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  :disabled="true"
                  class="paid-btn"
                  v-for="item in freeCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 無償理由選択プルダウン-->
            <div class="search-autocomplete" style="float: left">
              <v-text-field
                readonly
                dense
                class="list-single"
                v-model="freeReasonSelected"
                persistent-hint
                margin-bottom="0"
                :label="$t('label.lbl_freeReason')"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 備考欄-->
            <div class="search-textbox-remark" style="float: left">
              <v-text-field
                readonly
                dense
                v-model="remarks"
                :label="$t('label.lbl_remarksTextbox')"
                class="txt-single"
              />
            </div>
          </div>
        </v-row>
        <v-row>
          <!-- 3段目 -->
          <div class="search-row-exeBtn first-search-item">
            <!-- 処理区分-->
            <div class="search-autocomplete" style="float: left">
              <v-text-field
                dense
                readonly
                v-model="processDivSelected"
                :label="$t('label.lbl_processingdivision')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              ></v-text-field>
            </div>
          </div>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn color="primary" class="api-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="btn-area">
              <!-- 修正登録ボタン-->
              <v-btn color="primary" class="api-btn" value="1" v-on:click="shpArchieveAdd">
                {{ $t("btn.btn_fixinsert") }}
              </v-btn>

              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="addShpSch"
                :redMessage="ConfirmDialog.redMessage"
                :screenFlag="ConfirmDialog.screenFlag"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-container fluid>
        <v-form ref="editedList">
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <template v-slot:[`header.lot`]="{ header }">
              <!-- ロットヘッダー ツールチップ-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>{{ "説明" }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.shipAchieveQty`]="{ item, index }">
              <!-- 出荷予定数-->
              <v-text-field
                class="input-number"
                dense
                outlined
                v-model="item.shipAchieveQty"
                @change="changeQty(item, index)"
                :rules="[rules.isNumber]"
                clear-icon="mdi-close-circle"
                clearable
              />
            </template>
          </v-data-table>
        </v-form>

        <v-dialog v-model="updateDialogMessage" :max-width="800">
          <!-- 戻るボタンのダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ backMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="updateOk"> OK </v-btn>
              <v-btn @click="updateNg"> NO </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :screenFlag="infoDialog.screenFlag"
        :changeFlag="infoDialog.changeFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import LocationDialog from "../../views/common/Location";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";

export default {
  name: appConfig.SCREEN_ID.P_SHP_004,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    LocationDialog,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
    },
    editLocationSid: "",
    // ロケーションダイアログ
    dialogLocation: false,
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shpList: [],
    // 一覧index
    editedIndex: -1,
    // 処理区分
    processDivSelected: "",
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    locationMap: {},

    // 輸送会社名（登録用）
    truck: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 付帯情報ダイアログ
    dialogAddProudct: false,
    // 修正登録フラグ
    addFlg: false,
    // 伝票削除フラグ
    delFlg: false,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // 出荷No
    txt_slipNo: "",
    // 備考欄
    remarks: "",
    // 有償/無償
    isPaid: "",
    // 責任
    isRes: "",
    // 出荷先
    shippingSelected: "",
    // 処理区分
    shippingStatusSelected: "",
    // 無償理由
    freeReasonSelected: "",
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 出荷予定日付
    shippingAchieveDateDate: "",
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      redMessage: "",
      screenFlag: false,
      changeFlag: false,
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    productListIdx: -1,
    // 出荷予定品目一覧ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "5%",
        sortable: false,
        align: "center",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "45%",
        align: "left",
        sortable: false,
      },
      // 出荷予定数
      {
        text: i18n.tc("label.lbl_shippingProductQty"),
        value: "productQty",
        width: "10%",
        align: "right",
        sortable: false,
      },
      // 出荷実績数
      {
        text: i18n.tc("label.lbl_shipAchieveQty"),
        value: "shipAchieveQty",
        width: "10%",
        align: "right",
        sortable: false,
        class: "asta",
      },
      // 差
      {
        text: i18n.tc("label.lbl_difference"),
        value: "difference",
        width: "10%",
        align: "right",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lot",
        width: "20%",
        align: "left",
        sortable: false,
      },
    ],
    // データテーブル情報リスト
    inputList: [],
    // データテーブル明細情報
    defaultItem: {
      // No
      No: "",
      // 品番
      productCd: "",
      // 品名
      productNm: "",
      // 品番品名
      productCnCd: "",
      // 出荷予定数
      productQty: 0,
      // 出荷実績数
      shipAchieveQty: 0,
      // 差
      difference: 0,
      // ロット
      lot: "",
    },
    // 有償無償
    freeCostDivList: [],
    // 責任区分
    blameDivList: [],
    // 出荷明細プルダウン
    stockList: [],
    // 削除リスト
    deleteList: [],

    // 品番 品名プルダウン
    productList: [],
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    accList: [],
    accKeys: [],
    location: "",
    locationSidList: [],
    //検索に使う予定一覧のデータ
    shpInputDataList: [],
    rules: {
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      // フォーカス移動
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      // 出荷予定APIの検索条件を取得する。
      // 出荷伝票SID
      this.shipSid = this.$route.params.shipSid;

      // 出荷予定一覧「検索条件」の値を保持する。
      // 取引先SID
      this.searchSuppliersSelected = this.$route.params.suppliersSelected;
      // 出荷先SID
      this.searchShippingSelected = this.$route.params.shippingSelected;
      // 出荷日From
      this.searchshippingAchieveDateDateFrom = this.$route.params.shippingAchieveDateDateFrom;
      // 出荷日Fromカレンダー
      this.searchshippingAchieveDateDateFromCal = this.$route.params.shippingAchieveDateDateFromCal;
      // 出荷日To
      this.searchshippingAchieveDateDateTo = this.$route.params.shippingAchieveDateDateTo;
      // 出荷日Toカレンダー
      this.searchshippingAchieveDateDateToCal = this.$route.params.shippingAchieveDateDateToCal;
      // 発行条件（送り状）コード値
      this.searchIssueTermsSelected = this.$route.params.issueTermsSelected;
      // 発行条件（荷札）コード値
      this.searchTagTermsSelected = this.$route.params.tagTermsSelected;
      // 輸送会社名
      this.searchTruckNm = this.$route.params.truckNm;
      // コードマスタ取得
      this.getMstCode();
      // 出荷予定を取得
      this.getApiList();
      // 出荷明細と品番取得
      this.getItems();
    },

    /**
     * 出荷予定修正削除画面：出荷予定の明細と取引先・出荷先取得にアクセス
     */
    getItems(jstInScheduleDate) {
      this.getCustomInfo(jstInScheduleDate);
      this.getDetail();
    },

    /**
     * 出荷予定修正削除画面：明細API接続
     */
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.outListSid = this.shipSid;

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingAchieveDateDateJ);
      // console.debug("getDetail() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_OUTSCHEUDLES_DTL, config)
          .then((response) => {
            // console.debug("getDetail() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            const apiData = jsonData.resIdv.outScheduleDtls;

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              for (var j = 0; j < apiData.length; j++) {
                // API結果：出荷予定一覧明細
                let apiDataItem = apiData[j];

                // API結果保持変数
                let inputListItem = {};
                inputListItem.No = j + 1;
                // 出荷伝票明細SID
                inputListItem.outListSubSid = apiDataItem.outListSubSid;
                // 出荷伝票明細No
                inputListItem.outListSubNo = apiDataItem.outListSubNo;
                // 品番
                inputListItem.itemCd = apiDataItem.itemCd;
                // 品名
                inputListItem.itemName = apiDataItem.itemName;
                // 商品標準SID（商品コード/品名画面表示部分を設定）
                inputListItem.productCd = apiDataItem.itemStandardSid;
                // ロット
                inputListItem.lot = apiDataItem.lotNo;
                // 出荷予定数
                let productQtyNum = apiDataItem.shipScheduleQuantity;
                inputListItem.productQty = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );
                // ケース数
                let caseNum = apiDataItem.shipPackingQuantity;
                inputListItem.caseSu = String(commonUtil.formatToCurrency(Number(caseNum)));
                // ボール数
                let ballNum = apiDataItem.shipUnitQuantity;
                inputListItem.ballSu = String(commonUtil.formatToCurrency(Number(ballNum)));
                // バラ数
                let baraNum = apiDataItem.fracQuantity;
                inputListItem.baraSu = String(commonUtil.formatToCurrency(Number(baraNum)));
                // 明細更新日時
                inputListItem.updateDatetime = apiDataItem.updateDatetime;
                // 品質区分
                inputListItem.qualityDiv = apiDataItem.qualityDiv;
                // 品質区分名
                inputListItem.qualityDivName = apiDataItem.qualityDivName;
                // 付帯情報を設定
                let infoCategoryDtl = [];
                if (apiDataItem.outScheduleSub) {
                  for (var k = 0; k < apiDataItem.outScheduleSub.length; k++) {
                    var accInfoMap = {};
                    // 付帯情報リスト取得
                    let subItem = apiDataItem.outScheduleSub[k];
                    accInfoMap.accSelected = subItem.subInformation;
                    accInfoMap.accSelectedNm = subItem.subInformation;
                    accInfoMap.accValue = subItem.subInformationComment;
                    infoCategoryDtl.push(accInfoMap);
                  }
                }

                //LiputListItemに付帯情報を追加
                inputListItem.accInfoList = infoCategoryDtl;

                //ロケーション予約
                let locationReserveList = [];
                if (apiDataItem.locationReserve.length != 0) {
                  for (var l = 0; l < apiDataItem.locationReserve.length; l++) {
                    var warehouseMap = {};
                    let locaItem = apiDataItem.locationReserve[l];
                    warehouseMap.locationSid = locaItem.locationSid;
                    locationReserveList.push(warehouseMap);
                  }
                  // LiputListItemにロケ予約情報を追加
                  inputListItem.locationSid = locationReserveList[0].locationSid;
                } else {
                  // LiputListItemにロケ予約情報を追加
                  inputListItem.location = "";
                }

                // InputListに格納
                this.inputList.push(inputListItem);

                // 出荷修正削除画面の検索条件に値を設定する。
                // 品質区分名
                this.shippingStatusSelected = this.inputList[0].qualityDivName;
              }
              resolve(response);

              // エラー時
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * ロケAPI接続
     */
    getLocation() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      if (this.location) {
        config.params.locationNo = this.location;
      } else {
        config.params.locationSid = this.editLocationSid;
      }
      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingAchieveDateDateJ);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATION, config)
          .then((response) => {
            // console.debug("getLocation() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: row.locationNo,
                  value: row.locationSid,
                });
              });
              this.locationSidList = list;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 出荷修正削除画面： 最新の出荷予定一覧を取得
     */
    getApiList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // IF項目
      const config = this.$httpClient.createGetApiRequestConfig();

      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.searchSuppliersSelected;
      // 出荷伝票SID
      config.params.outListSid = this.shipSid;

      // console.debug("getApiList() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_OUTSCHEDULES, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getApiList() Response", response);

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              const list = [];

              // 出荷予定リストを取得
              jsonData.resIdv.outSchedules.forEach((row) => {
                list.push({
                  // 出荷日（API登録用=UTC）
                  shippingAchieveDateDateJ: row.outScheduleDate,
                  // 出荷日（画面表示用=JST:秒数なし）
                  shippingAchieveDateDate: dateTimeHelper
                    .convertUTC2JST(row.outScheduleDate)
                    .substr(0, 10),
                  // 出荷伝票SID
                  shipSid: row.outListSid,
                  // 出荷伝票No
                  shipNo: row.outListNo,
                  // toSid
                  toSid: row.toSid,
                  // 有償/無償区分
                  isPaid: row.freeCostDiv,
                  // 処理区分
                  processingdivision: row.processDiv,
                  // 処理区分名
                  processingdivisionNm: row.processDivName,
                  // 出荷先SID
                  shippingNm: row.toSid,
                  // 輸送会社名
                  carrierCompName: row.carrierCompCdName,
                  // 輸送会社区分
                  carrierCompCd: row.carrierCompCd,
                  // 路線業者SID
                  traderSid: row.deliveryTraderSid,
                  // 備考
                  remarks: row.remarks,
                  // 責任区分
                  blameDiv: row.blameDiv,
                  // 無償理由CD
                  reasonCd: row.reasonCd,
                  // 郵便番号
                  postCd: row.postCd,
                  // 送り先名
                  sendName: row.sendName,
                  // 都道府県
                  prefectures: row.prefectures,
                  // 市区町村
                  municipalities: row.municipalities,
                  // 字名
                  characterName: row.characterName,
                  // 電話番号
                  telephoneNumber: row.telephoneNumber,
                  // FAX番号
                  faxNumber: row.faxNumber,
                  // 送り状発行日
                  invoicePrintDate: row.invoicePrintDate,
                  // 荷札発行日
                  taglabelPrintDate: row.taglabelPrintDate,
                  // 理由CD名
                  reasonCdName: row.reasonCdName,
                  // 更新日時
                  updateDatetime: row.updateDatetime,
                });
              });
              this.shpInputDataList = list;

              // 出荷予定修正削除画面の検索条件に値を設定する。
              // 出荷No
              this.txt_slipNo = this.shpInputDataList[0].shipNo;
              // 出荷予定日（秒数なし）
              this.shippingAchieveDateDate = this.shpInputDataList[0].shippingAchieveDateDate;
              // 責任区分
              this.isRes = this.shpInputDataList[0].blameDiv;
              if (this.shpInputDataList[0].carrierCompCd == "04") {
                // 輸送会社区分が04（グループ会社）であった場合、路線業者SIDを設定
                this.truckNm = this.shpInputDataList[0].deliveryTraderSid;
              } else {
                // 輸送会社
                this.truckNm = this.shpInputDataList[0].carrierCompName;
              }
              // 有償無償
              this.isPaid = this.shpInputDataList[0].isPaid;
              // 無償理由
              this.freeReasonSelected = this.shpInputDataList[0].reasonCdName;
              // 備考欄
              this.remarks = this.shpInputDataList[0].remarks;
              // 処理区分名
              this.processDivSelected = this.shpInputDataList[0].processingdivisionNm;
              // 出荷先SID
              this.toSidDataList = this.shpInputDataList[0].toSid;
              // 出荷予定日（秒数あり）
              this.shippingAchieveDateDateJ = this.shpInputDataList[0].shippingAchieveDateDateJ;

              // 品番品名プルダウン取得
              this.getCdName();
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 出荷予定修正削除画面：戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-COM-001_004_W");
    },

    /**
     * 出荷予定修正削除画面：一覧画面へ渡す値を設定
     */
    updateOk() {
      this.updateDialogMessage = false;

      this.$router.push({
        name: "ShipAchieveList",
        params: {
          // 出荷予定一覧「検索条件」の値を保持する。
          // 取引先SID
          searchSuppliersSelected: this.searchSuppliersSelected,
          // 出荷先SID
          searchShippingSelected: this.searchShippingSelected,
          // 出荷日From
          searchshippingAchieveDateDateFrom: this.searchshippingAchieveDateDateFrom,
          // 出荷日Fromカレンダー
          searchshippingAchieveDateDateFromCal: this.searchshippingAchieveDateDateFromCal,
          // 出荷日To
          searchshippingAchieveDateDateTo: this.searchshippingAchieveDateDateTo,
          // 出荷日Toカレンダー
          searchshippingAchieveDateDateToCal: this.searchshippingAchieveDateDateToCal,
          // 発行条件（送り状）コード値
          searchIssueTermsSelected: this.searchIssueTermsSelected,
          // 発行条件（荷札）コード値
          searchTagTermsSelected: this.searchTagTermsSelected,
          // 輸送会社名
          searchTruckNm: this.searchTruckNm,
        },
      });
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    openLocaion() {
      this.dialogLocation = true;
      // console.debug(item);
    },

    /**
     * 出荷予定登録画面：メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 ケース数
     */
    changeCase(val, index) {
      var caseSu = commonUtil.zen2han(val.caseSu).replaceAll(",", "");
      if (!isNaN(Number(caseSu))) {
        this.inputList[index].caseSu = commonUtil.formatToCurrency(Number(caseSu));
      }
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 ボール数
     */
    changeBall(val, index) {
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");
      if (!isNaN(Number(ballSu))) {
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Number(ballSu));
      }
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 バラ数
     */
    changeBara(val, index) {
      var baraSu = commonUtil.zen2han(val.baraSu).replaceAll(",", "");
      if (!isNaN(Number(baraSu))) {
        this.inputList[index].baraSu = commonUtil.formatToCurrency(Number(baraSu));
      }
    },

    /**
     * ロケーション予約画面：登録後処理
     */
    saveLocation(data) {
      // 倉庫名
      this.warehouse = data;
      // 画面表示用ロケーション(ロケーションNo + 倉庫名称表示用)
      this.location = data.location + "（" + data.warehouseNameShort + "）";
      // ロケAPI
      this.getLocation();
      // ロケーションSID
      this.locationSid = data.locationSid;
      // ダイアログを閉じる
      this.dialogLocation = false;
    },

    /**
     * 付帯情報POPUP：付帯情報リストボックス取得処理
     */
    changeAcc(value, item) {
      for (var i = 0; i < this.accKeys.length; i++) {
        if (this.accKeys[i].value == value) {
          item.accSelectedNm = this.accKeys[i].text;
          break;
        }
      }
    },

    /**
     * 出荷予定登録画面：マスタから取得する配送先情報
     */
    getAccMst() {
      this.accKeys.push({ text: "材料", value: "material", required: "0" });
      this.accKeys.push({ text: "備考", value: "bigo", required: "0" });
    },

    /**
     * 出荷予定登録画面：商品コード変更時、行データ削除
     */
    changeProductNm(value, item) {
      if (this.inputList == "") {
        item.productNm = this.productList[i].name;
      } else {
        for (var i = 0; i < this.productList.length; i++) {
          if (this.productList[i].value == value) {
            item.productNm = this.productList[i].name;
          }
        }
      }
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報/ロケーション予約POPUP処理
     */
    openAccData(item) {
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      if (this.inputList[this.editedIndex].locationSid != undefined) {
        // ロケーションを取得し設定する。
        this.editLocationSid = this.inputList[this.editedIndex].locationSid;
        this.getLocation();
        this.location = this.locationSidList[0].text;

        let locationSid = this.inputList[this.editedIndex].locationSid;
        this.locationSid = locationSid;
      }

      // マスタを取得する。
      this.getMaster(item);

      this.dialogAddProudct = true;
    },

    /**
     * 付帯情報POPUP：登録処理
     */
    save() {
      // ツールチップの値
      var accInfos = this.setItemToList();
      // 付帯情報の名前のリスト
      var accInfoItem = this.setItemTo();
      // 付帯情報の値のリスト
      var accValue = this.setItemCon();

      // 付帯情報リストをクリアする
      this.accList.splice(0);

      // 付帯情報の名前と値のリストを作成する
      let ancillaryInfoList = [];

      // 付帯情報分リストを回す
      for (var i = 0; i < accInfoItem.length; i++) {
        // 付帯情報保持データ
        let ancillaryInfo = { accSelected: "", accSelectedNm: "" };

        // 名前
        ancillaryInfo.accSelected = accInfoItem[i];
        ancillaryInfo.accSelectedNm = accInfoItem[i];

        // 値
        ancillaryInfo.accValue = accValue[i];

        // 付帯情報の名前と値のリストに追加
        ancillaryInfoList.push(ancillaryInfo);
      }

      // 画面表示用付属情報（ツールチップ）
      this.inputList[this.editedIndex].accInfos = accInfos;

      // 付帯情報を設定
      this.inputList[this.editedIndex].accInfoList = ancillaryInfoList;

      // 値渡し用ロケリスト
      this.inputList[this.editedIndex].location = this.location;
      this.inputList[this.editedIndex].locationSid = this.locationSid;

      this.close();
    },

    /**
     * 出荷予定登録画面：付帯情報ツールチップ表示処理
     */
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfos.push(this.accList[i].accSelectedNm + ":" + this.accList[i].accValue);
      }
      return accInfos.join("\n");
    },

    /**
     * 出荷予定登録画面：付帯情報値変換処理(項目)
     */
    setItemTo() {
      // 付帯情報詳細処理
      const accInfoItem = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoItem.push(this.accList[i].accSelectedNm);
      }
      return accInfoItem;
    },

    /**
     * 取引先と出荷先取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      // To（出荷先）
      const toList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.FROMTO,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList, toList])
        .then((result) => {
          // 取引先
          this.supplierList = result[0];

          for (let i = 0; i < this.supplierList.length; i++) {
            if (this.searchSuppliersSelected == this.supplierList[i].value) {
              this.suppliersSelected = this.supplierList[i].text;
            }
          }

          // 出荷先
          this.shpList = result[1];
          for (let i = 0; i < this.shpList.length; i++) {
            if (this.toSidDataList == this.shpList[i].value) {
              this.shippingSelected = this.shpList[i].text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * コードマスタ取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      Promise.all([freeCostList, blameList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.freeCostDivList = result[0];
          // 責任区分の最初のなしを削除
          result[1].splice(0, 1);
          this.blameDivList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 出荷予定登録画面：付帯情報値変換処理(内容)
     */
    setItemCon() {
      // 付帯情報詳細処理
      const accInfoCon = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoCon.push(this.accList[i].accValue);
      }
      return accInfoCon;
    },

    /**
     * 出荷予定登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      this.deleteList.push(this.inputList[this.editedIndex]);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
    },

    /**
     * 出荷予定登録画面：付帯情報遷移処理
     */
    openAcc(item) {
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      // マスタを取得する。
      this.getMaster(item);

      this.dialogAddProudct = true;
    },

    /**
     * 付帯情報POPUP：閉じる処理
     */
    close() {
      this.dialogAddProudct = false;
      this.editedIndex = -1;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行削除処理
     */
    deleteAccItem(item) {
      this.editedIndex = this.accList.indexOf(item);
      this.accList.splice(this.editedIndex, 1);
    },

    /**
     * 出荷予定登録画面：商品コードリストボックスセット処理
     */
    checkProductCd(value) {
      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList[i].value == value.productCd) {
          return this.productList[i].name;
        }
      }
    },

    /**
     * 出荷予定登録画面：ケース数計算処理
     */
    setHintCase(value) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");
      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / 20));
      } else {
        return "";
      }
    },

    /**
     * 出荷予定登録画面：ボール数計算処理
     */
    setHintBall(value) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");
      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / 10));
      } else {
        return "";
      }
    },

    /**
     * 出荷予定登録画面：バラ数計算処理
     */
    setHintBara(value) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");
      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / 3));
      } else {
        return "";
      }
    },

    /**
     * 登録ボタン押下
     */
    shpArchieveAdd() {
      this.addFlg = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", ["修正内容を登録"]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },

    /**
     * 出荷予定登録画面： 品番/品名_業務利用商品API(GET)
     */
    getCdName() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const itemsBiz = getParameter.getItemsBiz(
        this.searchSuppliersSelected,
        this.shippingAchieveDateDateJ
      );

      Promise.all([itemsBiz])
        .then((result) => {
          // 画面の初期値を設定します。
          this.productList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 出荷実績API（POST）
     */
    addShpSch() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_EXT_004;
      // 出荷伝票SID
      body.reqIdv.outListSid = this.shpInputDataList[0].shipSid;
      // 営業所SID
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      body.reqIdv.clientSid = this.searchSuppliersSelected;
      // 出荷伝票No
      body.reqIdv.outListNo = this.txt_slipNo;
      // 出荷予定日
      body.reqIdv.outScheduleDate = dateTimeHelper.convertUTC(this.shippingAchieveDateDateJ);
      // 修正区分(02：修正)
      body.reqIdv.modifyDiv = "02";
      // 有償無償区分
      body.reqIdv.freeCostDiv = this.isPaid;
      // 処理区分
      body.reqIdv.processDiv = this.shpInputDataList[0].processingdivision;
      // 理由CD
      body.reqIdv.reasonCd = this.shpInputDataList[0].reasonCd;
      // 責任区分
      body.reqIdv.blameDiv = this.isRes;
      // FromSID
      body.reqIdv.fromSid = sessionStorage.getItem("sales_office_sid");
      // ToSid
      body.reqIdv.toSid = this.shpInputDataList[0].toSid;
      // 送り先名
      if (this.shpInputDataList[0].toSid == "") {
        body.reqIdv.postCd = this.shpInputDataList[0].postCd;
        body.reqIdv.sendName = this.shpInputDataList[0].sendName;
        body.reqIdv.prefectures = this.shpInputDataList[0].prefectures;
        body.reqIdv.municipalities = this.shpInputDataList[0].municipalities;
        body.reqIdv.characterName = this.shpInputDataList[0].characterName;
        body.reqIdv.telephoneNumber = this.shpInputDataList[0].telephoneNumber;
        body.reqIdv.faxNumber = this.shpInputDataList[0].faxNumber;
        body.reqIdv.toSid = "";
      }
      // 輸送会社区分
      body.reqIdv.carrierCompCd = this.shpInputDataList[0].carrierCompCd;

      // 輸送会社区分の判定
      if (this.truckKbn == appConfig.CARRIERCOMPDIV.DELIVERY) {
        // 輸送会社区分が04であれば、路線業者SIDを登録
        body.reqIdv.deliveryTraderSid = this.truck;
      }

      // 送り状発行日
      body.reqIdv.invoicePrintDate = this.shpInputDataList[0].invoicePrintDate;
      // 荷札発行日
      body.reqIdv.taglabelPrintDate = this.shpInputDataList[0].taglabelPrintDate;
      // 備考
      body.reqIdv.remarks = this.remarks;
      if (this.addFlg) {
        // 削除フラグ(修正登録)
        body.reqIdv.deleteFlg = "0";
      } else if (this.delFlg) {
        // 削除フラグ（伝票削除）
        body.reqIdv.deleteFlg = "1";
      }
      // 更新日時(出荷予定でGETした更新日時)
      body.reqIdv.updateDatetime = this.shpInputDataList[0].updateDatetime;

      // 出荷予定明細List
      var bodyList = [];

      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].productCd != "") {
          var inputMap = {};
          // 出荷伝票明細No
          inputMap.outListSubNo = this.inputList[i].No;
          // 出荷伝票明細Sid
          inputMap.outListSubSid = this.inputList[i].outListSubSid;
          // 商品標準SID
          inputMap.itemStandardSid = this.inputList[i].productCd;
          // 品番
          for (var m = 0; m < this.productList.length; m++) {
            if (this.productList[m].value == this.inputList[i].productCd) {
              inputMap.itemCd = this.productList[m].code;
            }
          }
          if (this.addFlg) {
            // 削除フラグ(修正登録)
            inputMap.deleteFlg = "0";
          } else if (this.delFlg) {
            // 削除フラグ（伝票削除）
            inputMap.deleteFlg = "1";
          }
          inputMap.opeDispName = i18n.tc("label.lbl_shipAchieveUpdateDeletepc");
          // ロット
          inputMap.lotNo = this.inputList[i].lot;
          // 出荷予定数
          inputMap.shipScheduleQuantity = this.inputList[i].productQty.replaceAll(",", "");
          // 出荷荷姿数
          inputMap.shipPackingQuantity = this.inputList[i].caseSu.replaceAll(",", "");
          // 出荷単位数
          inputMap.shipUnitQuantity = this.inputList[i].ballSu.replaceAll(",", "");
          // 端数
          inputMap.fracQuantity = this.inputList[i].baraSu.replaceAll(",", "");
          // 品質区分
          inputMap.qualityDiv = this.inputList[0].qualityDiv;
          // 更新日時(出荷詳細でGETしたの更新日時)
          inputMap.updateDatetime = this.inputList[i].updateDatetime;

          // 情報分類明細List
          this.infoCategoryDtl = [];
          //付帯情報
          if (this.inputList[i].accInfoList) {
            for (var j = 0; j < this.inputList[i].accInfoList.length; j++) {
              var accInfoMap = {};
              accInfoMap.subInformation = this.inputList[i].accInfoList[j].accSelected;
              accInfoMap.subInformationComment = this.inputList[i].accInfoList[j].accValue;
              this.infoCategoryDtl.push(accInfoMap);
            }
          }

          // ロケ予約List
          this.locationReserve = [];
          if (this.inputList[i].location) {
            var warehouseMap = {};
            warehouseMap.locationSid = this.inputList[i].locationSid;
            this.locationReserve.push(warehouseMap);
          }
          inputMap.locationReserve = this.locationReserve;
          inputMap.infoCategoryDtl = this.infoCategoryDtl;
          bodyList.push(inputMap);
        }
      }
      // 削除分の明細データをbodyListに追加する。
      // 画面上に表示されている明細
      for (var x = 0; x < this.deleteList.length; x++) {
        if (this.deleteList[x].productCd != "") {
          var inputMapdel = {};
          //入荷伝票明細No
          inputMapdel.outListSubNo = this.deleteList[x].No;
          // 入荷伝票SID
          inputMapdel.outListSubSid = this.deleteList[x].outListSubSid;
          //商品標準Sid
          inputMapdel.itemStandardSid = this.deleteList[x].productCd;

          // 品番
          for (var y = 0; y < this.productList.length; y++) {
            if (this.productList[y].value == this.deleteList[x].productCd) {
              //品番
              inputMapdel.itemCd = this.productList[y].code;
            }
          }
          // ロット
          inputMapdel.lotNo = this.deleteList[x].lot;
          // 入荷予定数
          inputMapdel.shipScheduleQuantity = this.deleteList[x].productQty.replaceAll(",", "");
          // ケース
          inputMapdel.shipPackingQuantity = this.deleteList[x].caseSu.replaceAll(",", "");
          // ボール
          inputMapdel.shipUnitQuantity = this.deleteList[x].ballSu.replaceAll(",", "");
          // バラ
          inputMapdel.fracQuantity = this.deleteList[x].baraSu.replaceAll(",", "");
          // 品質区分
          inputMapdel.qualityDiv = this.deleteList[x].qualityDiv;
          // 削除フラグ
          inputMapdel.deleteFlg = "1";
          // 更新日時(入荷詳細でGETしたの更新日時)
          inputMapdel.updateDatetime = this.deleteList[x].updateDatetime;

          // 情報分類明細List
          this.infoCategoryDtl = [];
          if (this.deleteList[x].accInfoList) {
            for (var z = 0; z < this.deleteList[x].accInfoList.length; z++) {
              var accInfoMapdel = {};
              accInfoMapdel.subInformation = this.deleteList[x].accInfoList[z].accSelected; //付帯情報
              accInfoMapdel.subInformationComment = this.deleteList[x].accInfoList[z].accValue; //付帯内容
              this.infoCategoryDtl.push(accInfoMapdel);
            }
          }

          // ロケ予約List
          this.locationReserve = [];
          if (this.deleteList[x].location) {
            var warehouseMapdel = {};
            warehouseMapdel.locationSid = this.deleteList[x].locationSid;
            this.locationReserve.push(warehouseMapdel);
          }

          //ロケ予約
          inputMapdel.locationReserve = this.locationReserve;
          //付帯情報
          inputMapdel.infoCategoryDtl = this.infoCategoryDtl;

          bodyList.push(inputMapdel);
        }
      }

      body.reqIdv.outScheduleDtl = bodyList;
      // console.debug("addShpSch() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          // TODO APIが出来たら修正する。
          .securePost(appConfig.API_URL.BIZ_OUTSCHEDULES, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addShpSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;

              // ボタン識別フラグ
              this.addFlg = false;
              this.delFlg = false;

              resolve(response);

              // エラー時
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//送り先登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}

//備考欄、テキストボックス
.search-textbox-remark {
  width: 40rem;
}
</style>
