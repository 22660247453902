<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-row>
        <v-col>
          <div id="lbl-screen-name">
            {{ $t("label.lbl_storableLocationLists") }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- 検索項目 -->
    <v-container fluid>
      <v-form ref="form" lazy-validation>
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <!-- 倉庫 -->
            <v-autocomplete
              dense
              id="get-warehouse"
              v-model="warehouseSelected"
              :items="warehouseList"
              :label="$t('label.lbl_warehouse')"
            >
              <!-- アイテム一覧の表示 -->
              <template slot="item" slot-scope="data">
                <span class="test">
                  {{ data.item.text }}
                </span>
              </template>
              <!-- * -->
            </v-autocomplete>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- ロケーション -->
          <div class="search-textbox">
            <v-text-field
              dense
              id="get-location"
              outlined
              v-model="txt_location"
              maxlength="30"
              :label="$t('label.lbl_location')"
              clear-icon="mdi-close-circle"
              clearable
            />
          </div>
        </v-row>
      </v-form>
      <!-- * -->

      <!-- 検索ボタン -->
      <v-row>
        <!-- 検索 -->
        <div class="search-row-exeBtn">
          <div class="btn-search-area" style="float: left">
            <v-btn color="primary" id="btn-search" class="get-btn" @click="locationSearch()">
              {{ $t("btn.btn_search") }}
            </v-btn>
          </div>

          <!-- 推奨ロケ検索 -->
          <!-- <v-col xl="2">
          <v-btn color="primary" @click="autoSearch()" small>
            {{ $t("btn.btn_recommendedLocationSearch") }}
          </v-btn>
        </v-col> -->

          <!-- 閉じるボタン -->
          <div class="btn-search-area" style="float: right">
            <v-btn color="primary" id="btn-search" class="other-btn" @click="close()">
              {{ $t("btn.btn_close") }}</v-btn
            >
          </div>
        </div>
      </v-row>

      <v-row>
        <div class="display-search-result-row">
          <!--検索結果表示-->
          <div class="text-label" style="float: left">
            <span>総件数：{{ totalCount }}件</span>
          </div>

          <!--表示件数切替部分-->
          <div style="float: right">
            <!--表示件数-->
            <div class="text-label" style="clear: both; float: left">
              {{ $t("label.lbl_itemsPerPage") }}
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!--表示件数切替ボタン-->
            <div style="float: left">
              <v-btn-toggle
                v-model="itemsPerPageBtn"
                color="blue darken-2"
                class="toggle black--text"
                right
              >
                <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                  {{ 10 }}
                </v-btn>
                <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                  {{ 50 }}
                </v-btn>
                <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                  {{ 100 }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>

    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        fixed-header
        :headers="headerItems"
        :items="locationList"
        :ripple="false"
        disable-filtering
        :options.sync="sortOptions"
        :server-items-length="itemsPerPage"
        :hide-default-footer="true"
        height="400px"
      >
        <!-- 倉庫名 -->
        <template v-slot:[`item.warehouse`]="{ item }">
          <div v-if="item.warehouse.length > 15">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.warehouse }}
                </div>
              </template>
              <span class="text-overflow">{{ item.warehouse }}</span>
            </v-tooltip>
          </div>
          <div class="text-overflow" v-else>{{ item.warehouse }}</div>
        </template>
        <!-- * -->

        <!-- ロケーション -->
        <template v-slot:[`item.location`]="{ item }">
          <div v-if="item.location.length > 20">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.location }}
                </div>
              </template>
              <span>{{ item.location }}</span>
            </v-tooltip>
          </div>
          <div v-else>{{ item.location }}</div>
        </template>
        <!-- * -->

        <!-- 商品コード・品名 -->
        <template v-slot:[`item.productCd`]="{ item }">
          <div v-if="item.productCd.length > 10">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.productCd }}
                </div>
                <div class="text-overflow" style="max-width: 200px">
                  {{ item.product }}
                </div>
              </template>
              <div>{{ item.productCd }}</div>
              <div>{{ item.product }}</div>
            </v-tooltip>
          </div>
          <div v-else>
            <div>{{ item.productCd }}</div>
            <div>{{ item.product }}</div>
          </div>
        </template>
        <!-- * -->

        <template v-slot:[`item.action`]="{ item }">
          <v-btn small @click="selectLocation(item)" text>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { i18n } from "../../lang/lang.js";
export default {
  components: {
    Loading,
  },
  // 親画面から渡される製品コード
  props: {
    locInfo: {},
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    u_10: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 選択した倉庫
    warehouseSelected: "",
    warehouseList: [],
    // 入力したロケーション
    txt_location: "",
    // 一覧
    locationList: [],
    // 一時保存用一覧リスト
    tempLocationList: [],
    // 検索件数
    totalCount: 0,
    // 総配列から抜き出した 倉庫配列
    warehouseLists: [],
    // 総データの重複削除配列
    filterwarehouseList: [],
    // 総配列から抜き出した ロケーションの配列
    locationNameList: [],
    // 倉庫の基準日
    dateW: "",
    // 検索の際の格納ロケの基準日
    dateL: "",
    // ソート
    sortItem: "",
    sortOptions: {},
    sortWareMap: {},
    sortLocaMap: {},
    sortProMap: {},
    sortQtyMap: {},
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "8%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "8%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "8%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "qty",
        width: "7%",
        align: "right",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_config"),
        value: "action",
        width: "4%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.warehouseSelected = "";
      this.location = "";
      this.getWarehouse(); // 倉庫API
      this.locationList = [];
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.locationSearch();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 0;
      this.locationSearch();
    },

    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      // 初期化
      this.pageCount = "";
      this.totalCount = 0;
      this.locationList = [];
      this.warehouseSelected = "";
      this.txt_location = "";
    },

    // 倉庫マスタ取得
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: row.warehouseLanguage[0].warehouseName,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 格納可能ロケーションの取得 (格納可能ロケ一覧API)
    locationSearch() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // IF共通項目生成
      const config = this.$httpClient.createGetApiRequestConfig();
      // マスタ取得有無 0：対象言語のみ、1：言語問わず全権取得
      config.params.isMst = "1";
      config.params.reqComPaginationFlg = "1"; //ページングする場合は1;
      config.params.reqComPageLimit = this.itemsPerPage;
      // config.params.reqComPageIndex = this.page;
      // ページャー処理
      if (this.page >= 2 && this.locationList.length == 0) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }

      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.locInfo.referenceDate);

      // 倉庫選択値
      if (this.warehouseSelected) {
        config.params.warehouseSid = this.warehouseSelected;
      } else {
        // 処理なし
      }
      // ロケーション入力値
      if (this.txt_location) {
        config.params.locationNo = this.txt_location;
      } else {
        // 処理なし
      }
      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }
      // console.debug("locationSearch() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_LOCATIONSAVA, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("locationSearch() Response", response);

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // クリア処理
              this.locationList = [];
              const list = [];
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  warehouse: row.warehouseName,
                  warehouseNameShort: row.warehouseNameShort,
                  warehouseSid: row.warehouseSid,
                  location: row.locationNo,
                  locationSid: row.locationSid,
                  productCd: row.itemCd,
                  product: row.itemName,
                  itemStandardsid: row.itemStandardsid,
                  qty: row.stock,
                });
                this.locationList = list;
                // ソート用Map作成
                this.sortWareMap["warehouse"] = "warehouseName";
                this.sortLocaMap["location"] = "locationNo";
                this.sortProMap["productCd"] = "itemCd";
                this.sortQtyMap["qty"] = "stock";
              });
            } else {
              // エラー時
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }

            resolve(response);
          })
          .catch((resolve) => {
            console.error("locationSearch() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // // 推奨ロケ検索
    // autoSearch() {
    //   this.locationList = [];
    //   for (var i = 1; i < 4; i++) {
    //     for (var j = 0; j < 5; j++) {
    //       var location = {
    //         warehouse: "warehouse" + i,
    //         location: i + "_" + j,
    //         productCd: this.locInfo.locProductCd,
    //         qty: i * 100,
    //       };
    //       this.locationList.push(location);
    //     }
    //   }
    // },

    /**
     * 倉庫セット
     */
    setWarehouse() {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == this.warehouseSelected) {
          return this.warehouseList[i].name;
        }
      }
    },

    // 選択したロケーション行
    selectLocation(data) {
      if (data.locationSid == this.locInfo.locationSid) {
        this.updateDialogMessage = true;
        this.alertMessage = messsageUtil.getMessage("P-MOV-002_001_E");
      } else {
        this.$emit("setLocation", data);
      }
    },
    reload() {},
  },
  computed: {},
  watch: {
    locInfo: function () {
      // console.debug("watch locInfo start");
      //this.init();
    },
    locationList: {
      handler: function () {
        console.debyg("watch locationList start");
      },
      deep: true,
    },
    page: function (newValue) {
      this.page = newValue;
      this.locationSearch();
    },

    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          if (sortBy == "warehouse") {
            sortBy = this.sortWareMap["warehouse"];
          } else if (sortBy == "location") {
            sortBy = this.sortLocaMap["location"];
          } else if (sortBy == "productCd") {
            sortBy = this.sortProMap["productCd"];
          } else if (sortBy == "qty") {
            sortBy = this.sortQtyMap["qty"];
          }
          this.sortItem = sortBy + " " + ascdesc;
          this.locationSearch();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
// popup画面の為、本画面ではmargin-leftは別設定
.search-button {
  width: 7rem;
}
.search-result {
  margin: 0 0 0 71%;
}

//データテーブル

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.first-btn-search {
  margin-left: -1.7%;
}
</style>
